// Font Family
$roboto: "Roboto", sans-serif;

// background ----------
$testi-dots: #c1c5cc;
$testi2-nav: #41444a;
$testi2-nav-icon: #8e96a4;
$testi2-pcolor: #c9c9c9;
$menuh-bg: #f5f8f9;
$services-itm-color: #fbf7ee;
$services-itm-color2: #f8f8f8;
$services-itm-color3: #f1f8f9;
$services-itm-color4: #f9f6f6;
$footer-bg: #1a1c20;
$footer-s-bg: #010101;
$visa-hover-bg: #f52f2f;
$pg-title-br: #707070;
$error-subs: #f6f9fe;
$faq-bg: #f8fafb;
$filter-border: #d9e5f9;
$country-popup: #0277c5;
// Colors ---------------
$white: #ffffff;
$grey: #f5f6f6;
$grey2: #f7f7f8;
$grey3: #f4f6f9;
$black: #222;
$hr-border-color: #eceff8;
$theme-color: #f52f2f;
$theme-color-blue: #2371ff;
$body-text-color: #6f6f6f;
$footer-text-color: #bcbfc9;
$border-color: #e7eaef;
$border-color2: #9b8d6c;
$border-color3: #b1b2b8;
$border-color4: #8aa1a4;
$border-color5: #c9a8a8;
$social-icon: #647589;
$span-color: #9ca1aa;
$footer-social-color: #636770;
$sbuscribe-input: #969ba4;
$visa-border: #e89d4a;
$busines-br: #f52f2f;
$ads-btn: #ffe600;
// Heading Color
$heading-color: #1a1c20;

// footer
$footer-text-color: #a6abb2;

// Soft color
$black-soft: #1d284b;
$grey-border: #d6d8db;

// Responsive Variables
$xxl: "only screen and (min-width: 1400px) and (max-width: 1599px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";
