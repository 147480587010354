/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,900;1,400;1,500;1,700&display=swap');
 body {
	font-family: $roboto;
	font-weight: 500;
    font-style: normal;
    color: $body-text-color;
    font-size: 16px;
    line-height: 28px;
}

.img,img {
	max-width: 100%;
	transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
    
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
button,
i {
	@include transition(.3s);
    text-decoration: none;
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover
{
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: medium none;
    text-decoration: none;
}
button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
input,
select,
textarea {
    font-family: $roboto;
    font-weight: 500;
    opacity: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $roboto;
	color: $heading-color;
	margin: 0px;
	font-style: normal;
	font-weight: 500;
	text-transform: normal;
    margin-bottom: 10px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
ul {
	margin: 0px;
	padding: 0px;
    
}
ul li{
    list-style: none;
}
p {
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	color: $body-text-color;
	margin-bottom: 10px;
}
hr {
	border-bottom: 1px solid $hr-border-color;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: $black-soft;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
.display-inline {
    display: inline-block;
}
*::-moz-selection {
	background: $black;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $black;
	color:$white;
	text-shadow: none;
}
::selection {
	background: $black;
	color: $white;
	text-shadow: none;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
}
input,
select {
   font-family: $roboto;
    font-weight: 500;
}
.custom-pad-20 {
    padding-left: 10px;
    padding-right: 10px;
}
.custom-mar-20 {
    margin-right: -10px;
    margin-left: -10px;
}
/*--
    - Input Placeholder
-----------------------------------------*/
*input::-moz-placeholder {
	font-size: 16px;
	opacity: 1;
    font-family: $roboto;
    font-weight: 500;
}
*input::placeholder {
	font-size: 16px;
	opacity: 1;
    font-family: $roboto;
    font-weight: 500;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: $grey;
}
.grey-soft-bg {
	background: $grey2;
}
.grey-soft2-bg {
	background: $grey3;
}
.white-bg {
	background:$white;
}
.black-bg {
	background: $black;
}
.black-soft-bg {
	background: $black-soft;
}
.services-itm-color{
    background: $services-itm-color;
}
.services-itm-color2{
    background: $services-itm-color2;
}
.services-itm-color3{
    background: $services-itm-color3;
}
.services-itm-color4{
    background: $services-itm-color4;
}
.theme-bg {
	background: $heading-color;
}
.visa-hover-bg{
    background: $visa-hover-bg;
}
.testi-dots{
    background-color: $testi-dots;
}
.testi2-nav{
    background-color: $testi2-nav;
}
.footer-bg{
    background: $footer-bg ;
}
.footer-text-color{
    color: $footer-text-color;
}

/*--
    - color
-----------------------------------------*/
.white-color {
	color: $white;
}
.black-color {
	color: $black;
}
.theme-color {
	color: $theme-color;
}

.footer-social-color{
    color: $footer-social-color;
}
.footer-s-bg{
    background: $footer-s-bg;
}
.sbuscribe-input{
    color: $sbuscribe-input;
}
.visa-border{
    color: $visa-border;
}
.faq-bg{
    background: $faq-bg;
}
// .primary-color {
// 	color: #222;
// }

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 1 through 40 {
    .mt-#{5 * $i}{margin-top: 5px * $i;}
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
    .mb-#{5 * $i}{margin-bottom: 5px *$i;}
}
/*-- Margin Left --*/
@for $i from 1 through 40 {
    .ml-#{5 * $i}{margin-left: 5px * $i;}
}

/*-- Margin Right --*/
@for $i from 1 through 40 {
    .mr-#{5 * $i}{margin-right: 5px *$i;}
}

/*-- Padding Top --*/
@for $i from 1 through 40 {
    .pt-#{5 * $i}{padding-top: 5px *$i;}
}

/*-- Padding Bottom --*/
@for $i from 1 through 40 {
    .pb-#{5 * $i}{padding-bottom: 5px *$i;}
}

/*-- Padding Left --*/
@for $i from 1 through 40 {
    .pl-#{5 * $i}{padding-left: 5px *$i;}
}

/*-- Padding Right --*/
@for $i from 1 through 40 {
    .pr-#{5 * $i}{padding-right: 5px *$i;}
}

// others common css here :)
.text-right{
    text-align: right;
}
.read-more {
    text-transform: uppercase;
    font-size: 14px;
    & a{
      font-weight: 500;
      & i{
          margin-left: 8px;
          position: relative;
          top: 1px;
          @include transition(.3s);
        }
        &:hover{
            color: $theme-color;
            & i{
              margin-left: 15px;
            }
        }
     }
  } 
  .pd-30{
      padding: 30px;
  }

// Page title start
  .page-title__area{
    position: relative;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);        
        top: 0;
        left: 0;    
        transform: scale(1);
        z-index: -1;
        }
}
.page__title-wrapper{
    & h3{
        color: $white;
        font-size: 70px;
        @media #{$sm,$xs} {
            font-size: 30px;
        }
    }
}
.bd-items{
    padding: 0px 10px;
    color: $white;
    font-size: 16px;
}
.bdritems{
   color: $pg-title-br;
}
.breadccrumb-bg{
    background: rgba(0,0,0,0.6); 
}
.breadcrumb{
    margin-bottom: 0px;
}
// Page title end

//section title style

//section title style

.subtitle {
        font-size: 15px;
        color: $theme-color;
        color: $body-text-color;
        margin-bottom: 15px;
        font-weight: 500;
        display: block;
        text-transform: uppercase;
        text-decoration: underline;
        @media #{$xs} {
            font-size: 14px;
            br {
                display: none;
            }
        }
}

.section-title {
        font-size: 48px;
        line-height: 1.2;
        margin-bottom: 0px;
        font-weight: 700;
        @media #{$lg} {
            font-size: 30px;
        }
        @media #{$md} {
            font-size: 30px;
        }
        @media #{$xs} {
            font-size: 25px;
            br{
                display: none;
            }
        }
        
}

//btn style

.theme-btn {
    font-size: 14px;
    color: $white;
    height: 60px;
    line-height: 60px;
    text-align: center;
    padding: 0px 70px 0px 40px;
    display: inline-block;
    background: $theme-color;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    border: 1px solid transparent;
    @include transition(.3s);
    &:focus{
        color: $white;
    }
    &:after {
        content: "\f061";
        font-family: "Font Awesome 5 pro";
        font-weight: 400;
        position: absolute;
        top: 0px;
        right: 32px;
    }
    &:before {
        content: "\f86c";
        font-family: "Font Awesome 5 pro";
        font-weight: 400;
        position: absolute;
        top: 0px;
        right: 48px;
        transform: rotate(90deg);
    }
    &:hover {
        background: $heading-color;
        color: $white;
        
    }
}

.blacks-hover:hover{
  border: 1px solid $white;
}

//custom z-index

.z-index {
    z-index: 2;
    position: relative;
}
.pb-285{
    padding-bottom: 285px;
}
.pb-310 {
    padding-bottom: 310px;
}
.pt-260 {
    padding-top: 260px;
}
.pt-250 {
    padding-top: 250px;
}
.pt-240 {
    padding-top: 240px;
}
.pt-235 {
    padding-top: 235px;
}
.pt-230 {
    padding-top: 230px;
}
.pt-220 {
    padding-top: 220px;
}
.pt-210 {
    padding-top: 210px;
}

//custom swipper dots
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    width: inherit;
}
.swiper-container-horizontal.common-dots {
    position: relative;
    & .slide-dots {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    display: inline-block;
    margin: 0px 5px;
    padding: 0px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #c6c9cb;
    display: inline-block;
    outline: none;
    cursor: pointer;
    background: none;
    font-size: 0px;
    opacity: 1;
    &.swiper-pagination-bullet-active {
        border-color: $theme-color-blue;
    }
}

.swiper-button-prev::after, .swiper-button-next::after {
	display: none;
}
.mt-10 {
  margin-top: 10px;
}