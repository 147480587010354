/*----------------------------------------*/
/*  06. Scholarship Css
/*----------------------------------------*/

/*--
    - Scholarship
------------------------------------------*/

.scholarship-area{
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    & img ::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;    
    transform: scale(1);
    }
}

.scholarship-left{
    position: absolute;
    bottom: 0;
    @media #{$lg} {
        width: 40%;
    }
    & img {
        @media #{$md} {
            display: none;
        }
        @media #{$xs} {
            display: none;
        }
        @media #{$sm} {
            display: none;
        }
    }
}

.scholarship__wrapper{
    padding-left: 80px;
    @media #{$xl} {
        padding-left: 80px;
    }
    @media #{$lg} {
        padding-left: 0px;
    }
    @media #{$md} {
        padding-left: 0px;
    }
    @media #{$xs} {
        padding-left: 0px;
    }
    @media #{$sm} {
        padding-left: 0px;
    }
    &-title{
        color: $white;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.27;
        @media #{$xl} {
            font-size: 30px;
        }
        @media #{$lg} {
            font-size: 30px;
            margin-bottom: 25px;
        }
        @media #{$md} {
            font-size: 30px;
            margin-bottom: 25px;
        }
        @media #{$xs} {
            font-size: 24px;
            margin-bottom: 25px;
        }
        @media #{$sm} {
            font-size: 24px;
            margin-bottom: 25px;
        }
    }  
    & p{
        color: $white;
        margin-bottom: 30px;
        @media #{$lg} {
            margin-bottom: 25px;
        }
    }
    & h5{
        font-size: 18px;
        margin-bottom: 35px;
        color: $white;
        font-weight: 400;
        @media #{$lg} {
            margin-bottom: 25px;
        }
    }
    &-img{
        @media #{$lg} {
            margin-bottom: 25px;
        }
       & img {
        margin-right: 10px;
        display: inline-block;
        @media #{$xl} {
            margin-right: 0px;
        }
        @media #{$lg} {
            margin-right: 0px;
        }
        @media #{$md} {
            margin-right: 0px;
        }
        @media #{$xs} {
            margin-right: 0px;
            margin-bottom: 5px;
        }
        @media #{$sm} {
            margin-right: 0px;
        }
    }    
    }                         
}





























.scholarship-area{
    position: relative;
    min-height: 620px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    & img ::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;    
    transform: scale(1);
    }
    &__left{
        position: absolute;
        bottom: 0;
        @media #{$lg} {
            width: 40%;
        }
        & img {
            @media #{$md} {
                display: none;
            }
            @media #{$xs} {
                display: none;
            }
            @media #{$sm} {
                display: none;
            }
        }
    }
   
    & .scholarship__wrapper__right{
        padding-left: 80px;
        @media #{$xl} {
            padding-left: 80px;
        }
        @media #{$lg} {
            padding-left: 0px;
        }
        @media #{$md} {
            padding-left: 0px;
        }
        @media #{$xs} {
            padding-left: 0px;
        }
        @media #{$sm} {
            padding-left: 0px;
        }
        & h2{
            color: $white;
            font-size: 36px;
            font-weight: 500;
            line-height: 1.27;
            margin-bottom: 35px;
            @media #{$xl} {
                font-size: 30px;
            }
            @media #{$lg} {
                font-size: 30px;
                margin-bottom: 25px;
            }
            @media #{$md} {
                font-size: 30px;
                margin-bottom: 25px;
            }
            @media #{$xs} {
                font-size: 24px;
                margin-bottom: 25px;
            }
            @media #{$sm} {
                font-size: 24px;
                margin-bottom: 25px;
            }
        }  
        & p{
            color: $white;
            margin-bottom: 35px;
            @media #{$lg} {
                margin-bottom: 25px;
            }
        }
        & h5{
            font-size: 18px;
            margin-bottom: 35px;
            color: $white;
            font-weight: 400;
            @media #{$lg} {
                margin-bottom: 25px;
            }
        }
        &__img{
            @media #{$lg} {
                margin-bottom: 25px;
            }
           & img {
            margin-right: 10px;
            display: inline-block;
            @media #{$xl} {
                margin-right: 0px;
            }
            @media #{$lg} {
                margin-right: 0px;
            }
            @media #{$md} {
                margin-right: 0px;
            }
            @media #{$xs} {
                margin-right: 0px;
                margin-bottom: 5px;
            }
            @media #{$sm} {
                margin-right: 0px;
            }
        }    
        }                         
    }
}

