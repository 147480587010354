/*----------------------------------------*/
/*  19. Steps Css
/*----------------------------------------*/
.steps-area{
    position: relative;
}
.steps-br {
	position: absolute;
    left: 26.6%;
    top: 10%;
    z-index: -1;
    @media #{$xxl} {
      left: 22.2%;
   }
    @media #{$xl} {
        left: 18%;
     }
     @media #{$lg,$md,$xs,$sm} {
        display: none;
     }
     
}
.steps-box{
   &:hover{
      & span{
         background: $theme-color;
         color: $white;
      }
      
   } 

    &__icon{
       & span{
        height: 96px;
        width: 96px;
        line-height: 96px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $theme-color;
        background-color: $white;
        color: $theme-color;
        font-size: 40px;
        font-family: flaticon;
        @include transition(.3s);
        
       }
    }
    &__content{
       & h4{
          font-size: 22px;
       }
       & p{
        line-height: 28px;
        padding: 0px 13px;
       }
    }
}
