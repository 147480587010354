@import '../scss/variables';
/*----------------------------------------*/
/*  18. News Details Css
/*----------------------------------------*/

.blog__date {
	background: $white;
    padding: 5px 20px;
    border-bottom: 4px solid $theme-color;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: -60px;
    z-index: 2;
    margin-bottom: 25px;
    left: 29px;
	& h3 {
		font-size: 34px;
		margin-bottom: 0px;
	}
    & i {
        font-size: 48px;
    }
    &.blog__date--service__icon {
        padding: 12px 15px 7px 15px;
    }
}
.ablog__text {
	padding: 42px 30px 0px 30px;
	border: 1px solid $border-color;
    position: relative;
}
.ablog__meta {
	margin-bottom: 15px;
	& ul {
		& li {
			display: inline-block;
			list-style: none;
			margin-right: 15px;
			&:last-child {
				margin-right: 0px;
			}
			& a {
			&:hover {
				color: $theme-color;
			}
				& i {
					font-size: 13px;
					margin-right: 2px;
				}
			}
		}
	}
}
.ablog__text--title {
	font-size: 27px;
	margin-bottom: 5px;
    @media #{$lg} {
        font-size: 26px;
    }
    @media #{$md} {
        font-size: 24px;
    }
	&:hover {
		color: $theme-color;
	}
}
.ablog__btn {
	& a {
		border-radius: 0px;
		transform: translateY(25px);
        height: 50px;
        line-height: 50px;
	}
}
.ablog__img {
    overflow: hidden;
    & img {
        width: 100%;
    }
}
.ablog {
    &:hover {
       & img {
            transform: scale3d(1.1, 1.1, 1.1);
        }
        & .ablog__user {
            & img {
                transform: none;
            }
        }
        & .blog__date {
            & i {
                animation-name: animation-pulse-shrink;
                animation-duration: 1.3s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                display: inline-block;
            }
        }
    }
}

//blog home two start
.ablog__user {
    display: flex;
    align-items: center;
    &--img {
        margin-right: 15px;
    }
    &--title {
        & h5 {
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
}
.ablog-2 {
    & p {
        margin-bottom: 25px;
    }
}
.ablog__text--title2 {
    margin-bottom: 10px;
}
.ablog__text2 {
    padding: 42px 30px 30px 30px;
}
.ablog__user--title {
    h5 {
        &:hover {
            color: $theme-color;
        }
    }
}
.blog__date2 {
    background: $theme-color;
}
//blog home two end

//blog home three start
.ablog__img3 {
    position: relative;
}
.ablog__text3 {
    position: inherit;
    padding: 0px 20px 30px 20px;
    border: none;
}
.blog__date3 {
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px;
}
.ablog__meta3 {
   box-shadow: 0px 5px 20px 0px rgba(0, 6, 91, 0.05);
    background: $white;
    border-radius: 3px;
    padding: 24px 43px;
    margin-top: -36px;
    position: relative;
    margin-bottom: 35px;
}
.ablog__text3--btn {
    & a.grey-btn {
        background: none;
        height: 52px;
        line-height: 50px;
        padding: 0px 35px;
        &:hover {
            background: $theme-color;
        }
    }
}
.ablog__text--title3 {
    margin-bottom: 15px;
}
.about--ablog__text3--btn {
    & a.theme-btn:hover {
        background: $theme-color-blue;
        border-color: $theme-color-blue;
    }
}
//blog home three end


//pagination css
.basic-pagination ul li {
	display: inline-block;
	margin-right: 10px;
    & a,
    & span {
       position: relative;
        overflow: hidden;
        background: #f1f1f1;
        color: $heading-color;
        font-size: 20px;
        display: inline-block;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px; 
    }
}
.basic-pagination ul li a:hover, .basic-pagination ul li span.current {
	background: $theme-color;
	color: $white;
}

//blog sidebar page start
.ablog-4 {
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
    -moz-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
    box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
}
.ablog__text4 {
    padding: 32px 40px 40px 40px;
    border-top: 0px;
}
.ablog__meta4 {
    margin-bottom: 20px;
    & ul li {
        & a {
            font-size: 18px;
            & i {
                font-size: 16px;
                color: $theme-color;
            }
        }
    }
}
.ablog__text--title4 {
    font-size: 36px;
    @media #{$xs} {
        font-size: 32px;
    }
    &:hover {
        color: $theme-color;
    }
}
.ablog__img4 {
    position: relative;
    & .avideo-btn-4 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.ablog__img--active {
	& .slide-prev,
	& .slide-next {
		position: absolute;
		color: $theme-color;
		font-size: 20px;
        height: 50px;
        width: 50px;
        line-height: 50px;
        background: $white;
		border: none;
		outline: none;
		cursor: pointer;
		z-index: 3;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        margin: 0px;
        border-radius: 50%;
		&:hover {
			background: $theme-color;
            color: $white;
		}
	}
	& .slide-prev {
		left: 40px;
        @media #{$xs} {
            left: 25px;
        }
        @media #{$sm} {
            left: 40px;
        }
	}
	& .slide-next {
		right: 40px;
        @media #{$xs} {
            right: 25px;
        }
        @media #{$sm} {
            right: 40px;
        }
	}
}
.ablog-4:hover img {
    transform: scale(1);
}

.sidebar--widget__search {
    & form {
        position: relative;
        & input {
            width: 100%;
            height: 60px;
            line-height: 60px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            border: none;
            outline: none;
            padding: 0 20px;
            background: #f7f7f7;
            color: #83868c;
        }
        & button {
            position: absolute;
            top: 0;
            right: 0;
            height: 60px;
            width: 50px;
            line-height: 60px;
            color: $theme-color;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
}
.sidebar--widget__post {
    display: flex;
    & .post__img {
        height: 90px;
        width: 80px;
        border-radius: 10px;
        background-size: cover;
        background-position: left;
        margin-right: 15px;
    }
    & .sidebar__post--text {
        overflow: hidden;
    }
}
.sidebar__post--title {
    font-size: 20px;
    margin-bottom: 5px;
    &:hover {
        color: $theme-color;
    }
}
.sidebar__widget--title {
    font-size: 26px;
    position: relative;
    padding-bottom: 12px;
    &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 60px;
        height: 2px;
        background: $theme-color;
    }
}
.sidebar--widget__cat {
    & ul {
        & li {
            margin-bottom: 15px;
            list-style: none;
            &:last-child {
                margin-bottom: 0px;
            }
            & a {
                display: inline-block;
                position: relative;
                padding-left: 20px;
                &:before {
                    position: absolute;
                    content: "\f054";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: "Font Awesome 5 Pro";
                    font-size: 14px;
                    color: $theme-color;
                    @include transition(.3s);
                }
                &:hover {
                    color: $theme-color;
                    &:before {
                        left: 7px;
                    }
                }
            }
        }
    }
}
.sidebar--widget__tag {
    & a {
        display: inline-block;
        height: 34px;
        line-height: 30px;
        text-align: center;
        padding: 0 19px;
        font-size: 14px;
        border: 2px solid #dce1e4;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        margin-right: 8px;
        margin-bottom: 10px;
        &:hover {
            background: $theme-color;
            color: $white;
            border-color: $theme-color;
        }
    }
}
.ablog__sidebar--wrapper {
    @media #{$lg,$md,$xs} {
        margin-right: 0px;
    }
}
//blog sidebar page end
.ablog__text4 {
    & blockquote {
        padding: 40px;
        color: #666;
        position: relative;
        background: #F8F8F8;
        font-style: normal;
        text-align: left;
        clear: both;
        font-weight: 400;
        border-radius: 10px;
        box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
        border: 1px solid #f6f6f8;
        margin-bottom: 30px;
        padding-bottom: 25px;
        @media #{$xs} {
            padding: 25px;
            padding-bottom: 10px;
        }
        @media #{$sm} {
            padding: 40px;
             padding-bottom: 25px;
        }
        &:before {
            content: "\f10e";
            position: static;
            font-family: 'Font Awesome 5 Pro';
            font-size: 32px;
            color: $theme-color;
            line-height: 1;
            margin-bottom: 18px;
            display: inline-block;
        }
        & cite {
            font-size: 18px;
            display: block;
            margin-top: 10px;
            color: $heading-color;
            font-style: inherit;
            &:before {
                content: "";
                display: inline-block;
                background: $theme-color;
                height: 2px;
                width: 40px;
                top: -4px;
                margin-right: 10px;
                position: relative;
            }
        }
    }
}
.blog__deatails--tag {
    & span {
        font-size: 18px;
        margin-right: 10px;
        margin-bottom: 0px;
        font-weight: 600;
        color: $heading-color;
    }
    & a {
        display: inline-block;
        height: 34px;
        line-height: 30px;
        text-align: center;
        padding: 0 19px;
        font-size: 14px;
        border: 2px solid #dce1e4;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        margin-right: 8px;
        @media #{$xs} {
            margin-bottom: 10px;
        }
        @media #{$sm} {
            margin-bottom: 0px;
        }
        &:hover {
            border-color: $theme-color;
            color: $white;
            background: $theme-color;
        }
    }
}
.blog__details--subtitle {
    font-size: 27px;
    margin-bottom: 15px;
}

//blog author
.blog__author {
	padding: 30px 40px;
	-webkit-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
	-moz-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
	box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
    @media #{$xs} {
        padding-bottom: 18px;
    }
    @media #{$sm} {
        padding-bottom: 30px;
        padding-bottom: 20px;
    }
    &-img {
        @media #{$xs} {
            margin-bottom: 10px;
        }
    }
}
.blog__author-content {
    & h5 {
        font-size: 18px;
	    margin-bottom: 0px;
    }
    & span {
        display: inline-block;
        margin-bottom: 5px;
    }
    & p {
        margin-bottom: 0px;
    }
}

//comment post
.post-comment-title h3 {
	font-size: 24px;
}
.latest-comments ul {
    list-style: none;
}
.latest-comments li:first-child .comments-box {
	border-top: 0;
	padding-top: 0;
}
.comments-avatar {
	float: left;
	margin-right: 20px;
}
.comments-text {
	overflow: hidden;
	border-bottom: 1px solid #f1f0f7;
	padding-bottom: 30px;
	margin-bottom: 30px;
    & p {
        margin-bottom: 20px;
    }
}
.avatar-name {
    margin-bottom: 10px;
	overflow: hidden;
	position: relative;
    & h5 {
        font-size: 18px;
	    margin-bottom: 0px;
    }
    & span {
        font-size: 14px;
	    font-weight: 500;
    }
}

//comment form
.comment-reply {
	font-weight: 600;
	font-size: 14px;
	color: $heading-color;
    & i {
        margin-right: 3px;
    }
    &:hover {
        color: $theme-color;
    }
}
.latest-comments li.children {
	margin-left: 105px;
    @media #{$xs} {
        margin-left: 0px;
    }
    @media #{$sm} {
        margin-left: 70px;
    }
}
.post-comment-form h4 {
	font-size: 24px;
	margin-bottom: 7px;
}
.post-comment-form > span {
	display: inline-block;
	margin-bottom: 45px;
}
.post-input input,
.post-input textarea {
	width: 100%;
	height: 50px;
	line-height: 50px;
	padding: 0 30px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	border: 2px solid #f5f5f5;
	background: #f5f5f5;
	margin-bottom: 20px;
    color: #83868c;
    &::-moz-placeholder {
        font-size: 16px;
        color: #83868c;
        opacity: 1;
    }

    &::placeholder {
        font-size: 16px;
        color: #83868c;
        opacity: 1;
    }
}
.post-input textarea {
	height: 180px;
	line-height: 26px;
	resize: none;
	padding: 30px;
	padding-top: 20px;
}

.ablog-4 {
    & .ablog__text4 {
        @media #{$xs} {
            padding: 17px 25px 25px 25px;
        }
        @media #{$sm} {
            padding: 32px 40px 40px 40px;
        }
    }
    & img {
        @media #{$xs} {
            min-height: 200px;
        }
    }
}
.blog__details--wrapper {
    @media #{$lg,$md,$xs} {
        margin-right: 0px;
    }
}
//blog details page end

