@import '../scss/variables';
/*----------------------------------------*/
/*  16. Testimonial Css
/*----------------------------------------*/

.testimonail__wrapper{
    background: $white;
    padding: 30px 30px;
    border: 1px solid $border-color;
    &__info{
        position: relative;
        &__img{
            margin-right: 20px;
            @media #{$lg} {
                margin-right: 15px;
             }
        }
        &__author{
            & h4{
            font-size: 22px;
            margin-bottom: 4px;
            @media #{$xl} {
            margin-bottom: 5px;
            }
            @media #{$lg} {
                margin-bottom: 5px;
                font-size: 15px;
            }
            @media #{$md} {
                margin-bottom: 5px;
                font-size: 15px;
            }
            }
            & span{
               font-size: 16px;
               font-weight: 400;
               margin-top: -5px;
               display: block;
               @media #{$lg} {
                font-size: 15px;
                }
            }
        }
        &__quotes{
           position: absolute;
           right: 0px;
           top: 0px;
           transform: rotate(180deg);
           @media #{$xl} {
            top: 12px;
            }
            @media #{$lg} {
                top: 12px;
             }
             @media #{$md} {
                top: 10px;
             }
           & i{
               font-size: 40px;
               color: $theme-color;
               @media #{$xl} {
                font-size: 20px;
                }
                @media #{$lg} {
                    font-size: 20px;
                }
                @media #{$md,$sm,$xs} {
                    font-size: 20px;
                }
           }
        }
    }
    
    &__content{
        & p{
           font-size: 16px;
           font-style: italic;
           font-weight: 400;
           margin-bottom: 20px;
           @media #{$lg} {
            font-size: 14px;
            }
            @media #{$md} {
                font-size: 14px;
            }
        }
        &__reviews{
            & ul{
                & li{
                   display: inline-block;
                   font-weight: 400;
                  & i{
                    color: $theme-color;
                  }
                }
            }
        }
    }

}

.testimonail-area .owl-carousel .owl-dots span {
	background: $testi-dots;
	width: 15px;
	height: 5px;
	display: inline-block;
    margin-right: 10px;
    border-radius: 50px;
}
.testimonail-area .owl-carousel .owl-dots {
	position: absolute;
	left: 50%;
	bottom: -80px;
	transform: translateX(-50%);
	display: flex;
}
.testimonail-area .owl-carousel .owl-dots .active span{
  background-color: $theme-color;
  }

// Testimoial-2
.testimonial-2{
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
        &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);        
        top: 0;
        left: 0;    
        transform: scale(1);
        z-index: -1;
        } 
        & .section-title{
            color: $white; 
        }
        & .subtitle{
            color: $white;
            
        }
}
.testimonail__wrapper2{
    background-color: transparent;
    z-index: 99;
    border: 0;
}
.testimonail__header{
   
    &__content{
        & h4{
            color: $white;
            font-size: 22px;
            margin-bottom: 10px;
        }
        & p{
            color: $white;
        }
    }
}
.testimonail__body{
    & p{
       color: $testi2-pcolor;
       padding: 0px 195px;
       font-style: italic;
       font-weight: 400;
       font: 16px;
       @media #{$md,$xs,$sm} {
       padding: 0px;
     }
    }
}

.testimonail__footer{
    & ul{
        & li{
            display: inline-block;
            margin-right: 5px;
            & i{
                color: $theme-color;
            }
            &:last-child{
                color: $white;
                margin-right: 0px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

//   testimonial-2 dots design

.testimonial-2 .owl-carousel .owl-item img {
	margin: auto;
    width: inherit;
}
.testimonial-2 .owl-carousel .owl-dots span {
	background: $testi-dots;
	width: 15px;
	height: 5px;
	display: inline-block;
    margin-right: 10px;
    border-radius: 50px;
}
.testimonial-2 .owl-carousel .owl-dots {
	position: absolute;
	left: 50%;
	bottom: -27px;
	transform: translateX(-50%);
	display: flex;
}
.testimonial-2 .owl-carousel .owl-dots .active span{
  background-color: $theme-color;
  }

//   testimonial-2 nav design
.testimonial-2 .owl-nav .owl-prev:before{
    font-family: "Font Awesome 5 Pro";
    font-size: 28px;
    content:"\f104";
 
}
.testimonial-2 .owl-nav span{
    display: none;

}
.testimonial-2 .owl-nav .owl-next:after{
    font-family: "Font Awesome 5 Pro";
    content: "\f105";
    font-size: 28px;

}
  .testimonial-2 .owl-nav button {
    position: absolute;
    top: 60%;
    left: -20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;

    width: 60px!important;
    height: 60px!important;
    line-height: 60px!important;
    color: $testi2-nav-icon!important;
    background-color: $testi2-nav!important;
    -webkit-border-radius: 50%!important;
    -moz-border-radius: 50%!important;
    border-radius: 50%!important;
    text-align: center!important;

    @media #{$sm} {
        display: none;
     }
     @media #{$xs} {
        display: none;
     }
    }
   
    .testimonial-2 .owl-nav button.owl-next {
    left: auto;
    right: -20px;
    
    }
    .testimonial-2 .owl-theme .owl-nav:hover button {
    background-color: $theme-color!important;
    color: #fff!important;
    
    }
