.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff!important;
    background-color: #fff!important;
    border-color: #dee2e6 #dee2e6 #fff!important;
    background: #f52f2f!important;
 
  }
  .nav-tabs{
    border-bottom: 0px!important;
    margin-right: 10px!important;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: inherit!important;
    isolation: isolate;
    border: 0px!important;
    cursor: pointer;
}

.nav-link{
  margin: 15px!important; 
  border: 0px!important;
  font-size: 14px;
  width: 150px!important; 
  text-align: center!important;
  border-radius: 5px!important;
  line-height: 29px!important;
  font-weight: 600!important;
  height: 48px!important;
  border: 0px !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.nav-tabs .nav-link {
  color: #002971;
}
.accordion-button:focus {
  z-index: 3;
  border-color: initial!important;
  outline: 0;
  box-shadow: initial!important;
}
.accordion-button:not(.collapsed) {
  color: initial!important;
  background-color: initial!important;
  box-shadow: initial!important;
}
.border_color.accordion-item , .border_color_top.accordion-item{
  border: 0px!important;
}
.accordion-item{
  border: 0px!important;
}