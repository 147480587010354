/*----------------------------------------*/
/*  10. Popularct Css
/*----------------------------------------*/

.popularct-area{
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
    &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;    
    transform: scale(1);
    z-index: -1;
    }
}
.popularct-extra{
    & h2{
        color: $white;
    }
    & span{
        color: $white;
    }
}
