/*----------------------------------------*/
/*  03. Hero Css
/*----------------------------------------*/

.aslider span {
    color: $white;
    margin-bottom: 25px;
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}
.aslider--title {
    font-size: 72px;
    line-height: 1.12;
    text-transform: uppercase;
    font-weight: 900;
    color: $white;
    @media #{$md} {
        font-size: 48px;
    }
    @media #{$xs} {
        font-size: 36px;
        br {
            display: none;
        }
    }
}
.aslider--subtitle {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: $white;
    @media #{$md} {
        font-size: 20px;
        br {
            display: none;
        }
    }
    @media #{$xs} {
        font-size: 16px;
        br {
            display: none;
        }
    }
    @media #{$sm} {
        font-size: 16px;
        br {
            display: none;
        }
    }
}
.single-slider {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider-height {
    min-height: 750px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        transform: scale(1);
        z-index: 1;
    }
    @media #{$md,$sm,$xs} {
        min-height: 600px;
    }
}
.slide-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transform: scale(1);
    @include transition(8s);
}
.swiper-slide-active .slide-bg {
    -webkit-transform: scale(1.12);
    -moz-transform: scale(1.12);
    transform: scale(1.12);
}

//slider arrow
.swiper-button-next {
    position: absolute;
    font-size: 20px;
    background: #ffffff;
    height: 65px;
    width: 65px;
    line-height: 65px;
    font-size: 20px;
    color: #1a1c20;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 3;
    text-align: center;
    top: 77%;
    transform: translateX(-50%);
    right: 43%;
    margin: 0px;
    vertical-align: middle;
    transition: all 0.3s ease-out 0s;
    box-shadow: 0px 0px 30px 0px rgba(0, 5, 63, 0.1);

    &:before {
        font-family: "Font Awesome 5 Pro";
        content: "\f178";
    }
    &:hover {
        background: $theme-color;
        color: $white;
    }
}
.swiper-button-prev {
    position: absolute;
    font-size: 20px;
    background: $white;
    height: 65px;
    width: 65px;
    line-height: 65px;
    font-size: 20px;
    color: $heading-color;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 3;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    margin: 0px;
    vertical-align: middle;
    @include transition(0.3s);
    box-shadow: 0px 0px 30px 0px rgba(0, 5, 63, 0.1);
    &:before {
        font-family: "Font Awesome 5 Pro";
        content: "\f177";
    }
    &:hover {
        background: $theme-color;
        color: $white;
    }
    @media #{$xxl,xl,$lg,$md,$sm} {
        top: inherit;
        bottom: 15%;
        left: 44.5%;
        transform: none;
    }
    @media #{$xl} {
        left: 44%;
        top: 90%;
    }
    @media #{$lg} {
        left: 42.5%;
    }
    @media #{$md} {
        left: 40.5%;
    }
    @media #{$sm} {
        left: 37.5%;
    }
    @media #{$xs} {
        display: none;
    }
    &:hover {
        background: $theme-color;
        color: $white;
    }
}
.slider-active .slide-next {
    left: auto;
    right: 40px;
    @media #{$xxl,xl,$lg,$md,$sm} {
        top: inherit;
        bottom: 15%;
        right: 44.5%;
    }
    @media #{$xl} {
        right: 44%;
    }
    @media #{$lg} {
        right: 42.5%;
    }
    @media #{$md} {
        right: 40.5%;
    }
    @media #{$sm} {
        right: 37.5%;
    }
    @media #{$xs} {
        display: none;
    }
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.7;
}

/* 02. Hero 2 CSS */
.slider-height-2 {
    min-height: 800px;
    @media #{$sm,$xs} {
        min-height: 600px;
    }
    &::before {
        background: transparent;
    }
}
.aslider-2 span {
    color: #fff;
    font-size: 24px;
    margin-bottom: 28px;
}
.aslider--title-2 {
    color: #fff;
}
.aslider--subtitle-2 {
    font-size: 22px;
    color: #fff;
}

.banner-content .form-inner {
    padding: 3px;
    width: 50%;
    @media #{$xl,$lg} {
        width: 55%;
    }
    @media #{$lg} {
        width: 65%;
    }
    @media #{$xs} {
        display: none;
    }
}
.banner-content .input-group .form-control::placeholder {
    color: $body-text-color;
    font-size: 14px;
}
.input-group-prepend span {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-top: -3px;
    font-weight: 500;
}
.banner-content .input-group .input-group-prepend {
    margin-right: 0;
}
.banner-content .form-control {
    border: 0;
    height: 60px;
    color: $black;
    font-size: 14px;
}
.drop-detail {
    width: 165px;
    display: flex;
    align-items: center;
}

.banner-content .nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: $white;
}
.banner-content .sub-btn:hover,
.banner-content .sub-btn:focus {
    background: $theme-color;
    border-color: $theme-color;
}
.banner-content .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: auto;
}

.banner__input {
    border: 1px solid transparent;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    font-size: 15px;
    color: #626975;
    background-color: $white;
    border-radius: 3px;
}
.input-group .nice-select:hover {
    border-color: transparent;
}

.banner-niceselect {
    width: 25%;
    @media #{$md} {
        width: 45%;
    }
    @media #{$sm} {
        width: 100%;
    }
}
.banner__input.nice-select span {
    font-size: 15px;
}
.banner-content span {
    color: $heading-color;
    margin-bottom: 30px;
    display: block;
    font-size: 18px;
    text-transform: capitalize;
}
.form-control:focus {
    color: #212529;
    background-color: $white;
    border-color: $white;
    outline: 0;
    box-shadow: none;
}
