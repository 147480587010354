/*--
    -09 Fact Css
------------------------------------------*/
.fact{
    padding: 30px 0px;
    border: 1px solid #E7EAEF;
    & h1{
       font-size: 50px;
       color: $theme-color;
       margin-bottom: 3px;
       @media #{$lg} {
        font-size: 40px;
      }
      & span{
        font-size: 50px;
       color: $theme-color;
      }
    }
    & span{
      font-size: 18px;
      color: $heading-color;
      font-weight: 500;
      @media #{$lg} {
        font-size: 16px;
      }
    }
}

// Face home 2
.fact-2{
  border: none;
   & h1{
    font-size: 50px;
    font-weight: 400;
    color: $theme-color;
    line-height: 53px;
    margin-bottom: 5px;
    @media #{$lg} {
     font-size: 40px;
   }
   & span{
     color: $theme-color !important;
   }
  }
  & span{
    font-size: 18px;
    line-height: 27px;
    color: $heading-color;
    font-weight: 400;
    @media #{$lg} {
      font-size: 16px;
    }
  }
}