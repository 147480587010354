@import '../scss/variables';
/*----------------------------------------*/
/*  21. Error Css
/*----------------------------------------*/

.error__title{
    font-size: 24px;
}
.error__content{
    & p{
        max-width: 590px;
	    margin: auto;
        margin-bottom: 20px;
    }
}

.error__subsribe-content .search input {
	width: 40%;
	height: 60px;
	line-height: 60px;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 25px 20px;
	background: $error-subs;
	color: $body-text-color;
    @media #{$xs} {
        width: 100%;
    }
    &:-moz-placeholder,&::placeholder{
        font-size: 15px;
    font-weight: 400;
    }
}
.error__subsribe-content .search {
	position: relative;
}
.error__subsribe-content .search button {
	position: absolute;
	top: 50%;
	right: 30%;
	transform: translateY(-50%);
	height: 50px;
	width: 50px;
	line-height: 50px;
	font-size: 16px;
	color: $heading-color;
	background: transparent;
    border: none;
	@media #{$xs} {
        right: 0;
    }
}


