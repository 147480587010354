@import '../scss/variables';
/*----------------------------------------*/
/*  26. Coaching  Css
/*----------------------------------------*/

.coacting-question{
    & p{
        color: $heading-color;
        margin-top: 40px;
    }
    & a {
        color: $theme-color;
        font-weight: 500;
    }
}

.course-filter{
    margin-bottom: 0px;
    text-align: right;
    @media #{$md,$xs,$sm} {
        text-align: left;
        margin-bottom: 30px;
    }
   
}
.courses__thumb-logo {
	position: absolute;
	bottom: -26px;
	left: 10.1%;
}
 .courses__thumb{
    position: relative;
    &-logo{
        position: absolute;
        bottom: -26px;
        left: 10.1%;
        }
        &-img {
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: 0.4s ease-in-out;
                @media #{$lg,$md,$xs,$sm} {
                    width: 100%;
                }
           }
        }
    }

  .courses:hover .courses__thumb-img img{
    transform: scale(1.1);
  }  
 .courses-content {
    border: 1px solid #E7EAEF;
    padding: 30px 40px 15px 40px;
     border: 1px solid $border-color;
     &__title{
        font-size: 22px;
        line-height: 32px;
        @include transition(.3s);
        &:hover{
            color: $theme-color;
        }
     }
    
 }
 .courses__meta{
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
     & ul{
        display: flex;
        justify-content: space-between;
         & li{
            border-right: 1px solid #ddd;
            text-align: center;
            width: 100%;
            &:last-child {
                max-width: 113px;
                border-right: 0;
            }
            & a{
                height: 50px;
                display: inline-block;
                line-height: 50px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                @media #{$xs} {
                    font-size: 14px;
                }
                &:hover{
                background: $theme-color;
                color: $white;
                }
                &.course-link-btn {
                    text-align: center;
                    & i{
                        margin-right: 0px;
                        margin-left: 5px;
                    }
                  }
                & i{
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }
         }
     }
 }
 
 .courses__meta ul li .border-none {
     border-left: none !important;
     
 }
.ask-here a{
    font-weight: 500;
    color: $heading-color;
    text-decoration: underline;
    &:hover{
        color: $theme-color;
    }
}